import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NotFoundIcon from "../images/error.svg"
const NotFoundPage = () => (
  <Layout>
    <Seo title="الصفحه غير متوفره!" />
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h1 style={{ color: "#2f0233" }} className="my-4 text-center">
            الصفحه غير متوفره!
          </h1>
          <img
            className="img-fluid"
            src={NotFoundIcon}
            alt="الصفحه غير متوفره!"
          />
        </div>
        <div className="col-md-12">
          <h3 className="my-3 text-center">
            الصفحه التي تحاول الدخول اليها , قد حذفت او تم تغيير الرابط.
          </h3>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
